import React from 'react';
import { graphql } from 'gatsby';
import { Card, List } from 'semantic-ui-react';
import styled from 'styled-components';

import Layout from '../components/layout';
import PrivateRoute from '../components/privateRoute';

const CurrentBoardSection = styled.section`
  padding: 2rem 0 5rem;
`;

const BoardIndex = ({ data }) => {
  const currentBoard = data.boards.edges[0].node;

  const classItems = data.boards.edges.map(({ node }) => (
    <Card
      key={node.id}
      color="red"
      centered
      style={{ maxWidth: 300, margin: '1rem auto' }}
    >
      <Card.Content style={{ textAlign: 'center' }}>
        <Card.Header style={{ fontSize: '1.5em', marginBottom: '1em' }}>
          Class of {node.year}
        </Card.Header>
        <Card.Description>
          <List relaxed size="large">
            {node.class.map(({ name, isClergy }) => (
              <List.Item key={name}>
                {name}
                {isClergy && '*'}
              </List.Item>
            ))}
          </List>
        </Card.Description>
      </Card.Content>
    </Card>
  ));

  return (
    <Layout
      headerSize="sm"
      headerImage={data.headerImage.childImageSharp}
      headerText={`${new Date().getFullYear()} Board`}
    >
      <CurrentBoardSection>
        <List relaxed size="huge" style={{ textAlign: 'center' }}>
          <List.Item>
            <List.Header style={{ marginBottom: '0.5em' }}>
              Chairman
            </List.Header>
            <List.Description>{currentBoard.chairman}</List.Description>
          </List.Item>
          <List.Item>
            <List.Header style={{ marginBottom: '0.5em' }}>
              Vice Chairman
            </List.Header>
            <List.Description>{currentBoard.viceChairman}</List.Description>
          </List.Item>
          <List.Item>
            <List.Header style={{ marginBottom: '0.5em' }}>
              Secretary
            </List.Header>
            <List.Description>{currentBoard.secretary}</List.Description>
          </List.Item>
          <List.Item>
            <List.Header style={{ marginBottom: '0.5em' }}>
              Treasurer
            </List.Header>
            <List.Description>{currentBoard.treasurer}</List.Description>
          </List.Item>
          <List.Item>
            <List.Header style={{ marginBottom: '0.5em' }}>
              Community Spiritual Director
            </List.Header>
            <List.Description>
              {currentBoard.communitySpiritualDirector}
            </List.Description>
          </List.Item>
        </List>
      </CurrentBoardSection>
      <section style={{ width: '100%' }}>
        <Card.Group centered itemsPerRow={3} stackable textAlign="center">
          {classItems}
        </Card.Group>

        <p className="my-10 text-center">* denotes clergy</p>
      </section>
    </Layout>
  );
};

export default props => {
  return <PrivateRoute component={BoardIndex} {...props} />;
};

export const query = graphql`
  query BoardQuery($year: Float!) {
    boards: allSanityBoard(
      sort: { fields: year, order: ASC }
      filter: { year: { gte: $year } }
      limit: 3
    ) {
      edges {
        node {
          id
          year
          chairman
          viceChairman
          secretary
          treasurer
          communitySpiritualDirector
          exOfficio
          class {
            name
            isClergy
          }
        }
      }
    }

    headerImage: file(relativePath: { eq: "path.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
